<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Form from "@/domain/priceListTemplate/components/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewPriceList',
  components: {Form},
  data() {
    return {
      empty: {
        display_name: '',
        currency: null,
        items: [],
        configuration: {}
      }
    }
  },
  methods: {
    ...mapActions('priceListTemplate', [
      'createPriceList',
    ]),
    async onSave(data) {
      await this.createPriceList(data)
      await this.$router.push({name: 'priceListTemplate.list'})
      throw new SuccessNotification(this.$pgettext('price_list_template', 'Price List Template created!'))
    }
  }
}
</script>